import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";

Vue.use(ElementUI);

// 阻止显示生产模式的消息
Vue.config.productionTip = false;
initVue();
function initVue() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}