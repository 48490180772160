import Vue from "vue";
import Vuex from "vuex";
import { buildMutType } from '@/utils/mutation-util';
import { axiosJson } from '@/utils/action-util';

const GET_MIRRORS_LIST = buildMutType('GET_MIRRORS_LIST');
const GET_CONFIG_LIST = buildMutType('GET_CONFIG_LIST');
const GET_INSTALL_LIST = buildMutType('GET_INSTALL_LIST');

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    mirrorsList: [],
    configList: [],
    installList: [],
    mirrorsDetail: {},
  },
  mutations: {
    [GET_MIRRORS_LIST.REQUEST](state) {
      state.mirrorsList = [];
    },
    [GET_MIRRORS_LIST.SUCCESS](state, payload) {
      state.mirrorsList = payload;
    },
    [GET_MIRRORS_LIST.FAIL](state) {
      state.mirrorsList = [];
    },
    [GET_CONFIG_LIST.REQUEST](state) {
      state.configList = [];
    },
    [GET_CONFIG_LIST.SUCCESS](state, payload) {
      state.configList = payload;
    },
    [GET_CONFIG_LIST.FAIL](state) {
      state.configList = [];
    },
    [GET_INSTALL_LIST.REQUEST](state) {
      state.installList = [];
    },
    [GET_INSTALL_LIST.SUCCESS](state, payload) {
      state.installList = payload;
    },
    [GET_INSTALL_LIST.FAIL](state) {
      state.installList = [];
    },
  },
  actions: {
    // 加载镜像列表
    getMirrorsList({ commit }) {
      return axiosJson(
        commit,
        'list.json',
        GET_MIRRORS_LIST,
      );
    },
    // 加载配置数据
    getConfigList({ commit }) {
      return axiosJson(
        commit,
        'configuration.json',
        GET_CONFIG_LIST,
      );
    },
    // 加载下载数据
    getInstallList({ commit }) {
      return axiosJson(
        commit,
        'installOption.json',
        GET_INSTALL_LIST,
      );
    },
  },
});
