const JUDGE_ERROR_TYPE = {
  REQUEST: '请求异常！',
  SERVER: '后台服务异常！',
  SYSTEM: '系统异常！',
  TIMEOUT: '当前网络状态不佳，请稍后再试',
};

 
const NO_VALUE_SHOW = '--';


const HTTP_STATUS = {
  SUSSCESS: 2,
  CLIENT: 4
};

const DETAIL_LIST = [
  'bclinux', 'anolis', 'openeuler', 'centos', 'centos-vault', 'elrepo',
  'epel', 'fedora', 'opensuse', 'rocky', 'ubuntu-releases', 'ubuntu', 'apache',
  'ceph', 'elasticstack', 'jenkins', 'mongodb', 'mysql', 'postgresql', 'zabbix',
  'loongson', 'nodejs-release', 'docker-ce'
]

export {
  JUDGE_ERROR_TYPE, NO_VALUE_SHOW, HTTP_STATUS, DETAIL_LIST
}
