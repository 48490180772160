import QueryString from 'querystring';
import axios from 'axios';
import { service } from '@/utils/axios.js';


const axiosPost = async (commit, url = '', params = null, body = null, mutationType = '') => {
  commit(mutationType.REQUEST);
  const fullUrl = params ? `${url}?${QueryString.stringify(params)}` : url;
  try {
    const result = await service.post(fullUrl, body);
    commit(mutationType.SUCCESS, result);
  } catch (e) {
    commit(mutationType.FAIL);
    return Promise.reject(e);
  }
};

const axiosPostNotCommit = async (url = '', body = null) => {
  try {
    await service.post(url, body);
  } catch (e) {
    return Promise.reject(e);
  }
};


const axiosJson = async (commit, url = '', mutationType = '') => {
  commit(mutationType.REQUEST);
  try {
    let jsonData = {};
    await axios(url).then((result) => {
      jsonData = result.data;
    });
    commit(mutationType.SUCCESS, jsonData);
  } catch (e) {
    commit(mutationType.FAIL);
    return Promise.reject(e);
  }
};


export {
  axiosPost, axiosJson, axiosPostNotCommit,
};
