import Vue from "vue";
import VueRouter from "vue-router";
import { routerBeforeEachSkip } from '@/utils/router-util.js';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
router.beforeEach((to, from, next) => {
  routerBeforeEachSkip(to, from, next);
});

export default router;
