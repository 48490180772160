<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted () {
  },
};
</script>

<style lang="scss">
  #app {
    min-height: 100%;
    min-width: 1280px;
    background: #F7F7F7;
  }
</style>
