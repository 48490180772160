import axios from 'axios';
import store from "../store";
import { Message } from 'element-ui'
import { HTTP_STATUS, JUDGE_ERROR_TYPE } from '@/utils/constant';

const responseSuccessInterceptor = (response) => {
  const resData = response.data;
  const { status } = response;
  const codePrefix = Math.floor(status / 100);
  let desc = '';
  if (codePrefix !== HTTP_STATUS.SUSSCESS) {
    // HTTP状态码不是成功情况下的处理
    if (codePrefix === HTTP_STATUS.CLIENT) {
      desc = JUDGE_ERROR_TYPE.REQUEST;
    } else {
      desc = JUDGE_ERROR_TYPE.SERVER;
    }
    Message({
      message: desc,
      duration: 3000,
      type: 'error'
    });
    store.commit('setErrorMessage', desc);
    // 返回一个带有拒绝原因（reason参数）的Promise对象
    return Promise.reject(response);
  }
  if ((resData instanceof Blob) || ((`${resData}`) === '[object Blob]')) {
    // 若是文件流，则直接返回
    return resData;
  }
  const { code, message, data } = resData;
  if (code === 0) {
    return data;
  } else {
    if (message === undefined) {
      desc = JUDGE_ERROR_TYPE.SYSTEM
      Message({
        message: desc,
        duration: 3000,
        type: 'error'
      });
    } else {
      desc = message
      Message({
        message: desc,
        duration: 3000,
        type: 'error'
      });
    }
    store.commit('setErrorMessage', desc);
    return Promise.reject(response);
  }
};

const responseErrorInterceptor = (error) => {
  if(error.message.includes('timeout')){
    Message.error({
      message: JUDGE_ERROR_TYPE.TIMEOUT,
    });
  } else {
    Message.error({
      message: JUDGE_ERROR_TYPE.SYSTEM,
    });
  }
  return Promise.reject(error);
};

// 创建axios实例
const service = axios.create({
  timeout: 30000, // 请求超时时间
});

service.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export { responseSuccessInterceptor, responseErrorInterceptor, service };
